import { Domain } from 'api';

import { customerApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.CustomerDetails>({
    getState: rootState => rootState.customer.details,
});

export const reducerActions = makeReducerActions<Domain.CustomerDetails>({
    reducerPrefix: '@customer/details',
});

export const actions = makeActions<Domain.CustomerDetails>({
    loadApi: options => customerApi.GetCustomerDetails(options.urlParams.identifiedCustomerId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.CustomerDetails>({
    reducerActions,
});
