import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopCustomerApi, webshopApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setWebshop = createAction('@webshopCustomer/details/setWebshop', withPayloadType<Domain.WebshopDetails>());
export const selectWebshop: Selector<RootState, Domain.WebshopDetails | undefined> = state => state.webshopCustomer.details.webshop;

export const selectors = makeSelectors<Domain.WebshopCustomerDetails>({
    getState: rootState => rootState.webshopCustomer.details,
});

export const reducerActions = makeReducerActions<Domain.WebshopCustomerDetails>({
    reducerPrefix: '@webshopCustomer/details',
});

const baseActions = makeActions<Domain.WebshopCustomerDetails>({
    loadApi: options => webshopCustomerApi.GetWebshopCustomerDetails(options.urlParams.identifiedCustomerId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<
    Domain.WebshopCustomerDetails,
    {
        webshop?: Domain.WebshopDetails;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder.addCase(setWebshop, (state, action) => {
            state.webshop = action.payload;
        }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const customer = await dispatch(baseActions.load(options));
            if (customer) {
                const webshop = await webshopApi.GetWebshopDetails(customer.webshopId);
                dispatch(setWebshop(webshop));
            }
        },
};
