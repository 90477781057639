import { v4 as uuidv4 } from 'uuid';

export const notification = () => 'notification-' + uuidv4();
export const user = () => 'user-' + uuidv4();
export const company = () => 'company-' + uuidv4();
export const branch = () => 'branch-' + uuidv4();
export const device = () => 'device-' + uuidv4();
export const product = () => 'product-' + uuidv4();
export const mediaItem = () => 'mediaItem-' + uuidv4();
export const productWall = () => 'productWall-' + uuidv4();
export const canvasFrame = () => 'canvasFrame-' + uuidv4();
export const customPage = () => 'customPage-' + uuidv4();
export const slideshow = () => 'slideshow-' + uuidv4();
export const slideshowSlide = () => 'slideshowSlide-' + uuidv4();
export const timeline = () => 'timeline-' + uuidv4();
export const webshop = () => 'webshop-' + uuidv4();
export const webshopPage = () => 'page-' + uuidv4();
export const webshopPageBlock = () => 'block-' + uuidv4();
export const webshopPageBlockWidget = () => 'widget-' + uuidv4();
export const webshopNewsArticle = () => 'article-' + uuidv4();
export const webshopTag = () => 'tag-' + uuidv4();
export const webshopDeliveryTime = () => 'deliveryTime-' + uuidv4();
export const webshopPromotion = () => 'promotion-' + uuidv4();
export const schedule = () => 'schedule-' + uuidv4();
export const scheduleSlot = () => 'slot-' + uuidv4();
export const sharingGroup = () => 'sharingGroup-' + uuidv4();
export const webshopFaqCategory = () => 'faq-cat-' + uuidv4();
export const webshopFaqItem = () => 'faq-item-' + uuidv4();
export const integration = () => 'integration-' + uuidv4();
export const robot = () => 'robot-' + uuidv4();
export const webshopNewsArticleBlock = () => 'articleBlock-' + uuidv4();
export const reseller = () => 'reseller-' + uuidv4();
export const orderShipment = () => 'shipment-' + uuidv4();
export const VENDING_MACHINE_ORDER = () => 'SC-' + uuidv4();
export const qualityLabel = () => 'qualityLabel-' + uuidv4();
export const webshopColorSet = () => 'colorset-' + uuidv4();
export const VENDING_MACHINE_CART_ITEM_ID = () => 'CART_ITEM-' + uuidv4();
export const VENDING_MACHINE_CUSTOMER_REQUEST_ITEM_ID = () => 'CUSTOMER_REQUEST_ITEM_ID-' + uuidv4();
export const deviceTransaction = () => 'transaction-' + uuidv4();
export const NIGHT_HATCH_TRANSACTION_ID = () => 'NIGHT_HATCH_TRANSACTION_ID-' + uuidv4();
export const nightHatchLog = () => 'nightHatchLog-' + uuidv4();
export const pickup = () => 'pickup-' + uuidv4();
export const deviceOrder = () => 'order-' + uuidv4();
export const customVendingMachineHomeScreenCategory = () => 'customVendingMachineHomeScreenCategory-' + uuidv4();
export const webhook = () => 'webhook-' + uuidv4();
export const shipmentItem = () => 'shipmentItem-' + uuidv4();
export const webshopAnnouncement = () => 'announcement-' + uuidv4();
export const appointmentType = () => 'appointmentType-' + uuidv4();
export const appointment = () => 'appointment-' + uuidv4();
export const headerContent = () => 'headerContent-' + uuidv4();
export const cmsItem = () => 'cmsItem-' + uuidv4();
export const cmsItemBlock = () => 'cmsItemBlock-' + uuidv4();
export const cmsTag = () => 'cmsTag-' + uuidv4();
export const customer = () => 'identifiedCustomer-' + uuidv4();

export const tag = () => 'tag-' + uuidv4();
