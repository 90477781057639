import * as React from 'react';

import classNames from 'classnames';

import { colorTransform } from '@/color';
import { BaseProps } from '@/core';

import styles from './Icon.scss';

const ASSETS = {
    action_add: require('./assets/action/add.svg'),
    action_add_box: require('./assets/action/add_box.svg'),
    action_arrow_down: require('./assets/action/arrow_down.svg'),
    action_arrow_left: require('./assets/action/arrow_left.svg'),
    action_arrow_right: require('./assets/action/arrow_right.svg'),
    action_arrow_up: require('./assets/action/arrow_up.svg'),
    action_background: require('./assets/action/background.svg'),
    action_bell: require('./assets/action/bell.svg'),
    action_bell_on: require('./assets/action/bell_on.svg'),
    action_calendar: require('./assets/action/calendar.svg'),
    action_check: require('./assets/action/check.svg'),
    action_circle: require('./assets/action/circle.svg'),
    action_column: require('./assets/action/column.svg'),
    action_copy: require('./assets/action/copy.svg'),
    action_delete: require('./assets/action/delete.svg'),
    action_drag: require('./assets/action/drag.svg'),
    action_drag_vertical: require('./assets/action/drag_vertical.svg'),
    action_dots_vertical: require('./assets/action/dots_vertical.svg'),
    action_dots_horizontal: require('./assets/action/dots_horizontal.svg'),
    action_duplicate: require('./assets/action/duplicate.svg'),
    action_edit: require('./assets/action/edit.svg'),
    action_eyedropper: require('./assets/action/eyedropper.svg'),
    action_filter: require('./assets/action/filter.svg'),
    action_first_page: require('./assets/action/first_page.svg'),
    action_flip_up: require('./assets/action/flip_up.svg'),
    action_flip_down: require('./assets/action/flip_down.svg'),
    action_last_page: require('./assets/action/last_page.svg'),
    action_left: require('./assets/action/left.svg'),
    action_list: require('./assets/action/list.svg'),
    action_lock: require('./assets/action/lock.svg'),
    action_media: require('./assets/action/media.svg'),
    action_minus: require('./assets/action/minus.svg'),
    action_moon: require('./assets/action/moon.svg'),
    action_move: require('./assets/action/move.svg'),
    action_move_up: require('./assets/action/move_up.svg'),
    action_move_down: require('./assets/action/move_down.svg'),
    action_phone: require('./assets/action/phone.svg'),
    action_phone_hangup: require('./assets/action/phone-hangup.svg'),
    action_photo: require('./assets/action/photo.svg'),
    action_play_outline: require('./assets/action/play-outline.svg'),
    action_play: require('./assets/action/play.svg'),
    action_pause: require('./assets/action/pause.svg'),
    action_preview: require('./assets/action/preview.svg'),
    action_product: require('./assets/action/product.svg'),
    action_rating: require('./assets/action/star.svg'),
    action_redo: require('./assets/action/redo.svg'),
    action_restrict: require('./assets/action/restriction.svg'),
    action_remove: require('./assets/action/remove.svg'),
    action_remove_box: require('./assets/action/remove_box.svg'),
    action_reset: require('./assets/action/reset.svg'),
    action_right: require('./assets/action/right.svg'),
    action_save: require('./assets/action/save.svg'),
    action_search: require('./assets/action/search.svg'),
    action_setting: require('./assets/action/setting.svg'),
    action_skip_next: require('./assets/action/skip_next.svg'),
    action_skip_previous: require('./assets/action/skip_previous.svg'),
    action_sun: require('./assets/action/sun.svg'),
    action_swap: require('./assets/action/swap.svg'),
    action_table: require('./assets/action/table.svg'),
    action_text: require('./assets/action/text.svg'),
    action_toggle_off: require('./assets/action/toggle_off.svg'),
    action_toggle_on: require('./assets/action/toggle_on.svg'),
    action_undo: require('./assets/action/undo.svg'),
    action_user: require('./assets/action/user.svg'),
    action_visibility_off: require('./assets/action/visibility_off.svg'),
    action_visibility_on: require('./assets/action/visibility_on.svg'),
    action_megaphone: require('./assets/action/megaphone.svg'),
    action_megaphone_dot: require('./assets/action/megaphone_dot.svg'),
    action_download_export: require('./assets/action/download_export.svg'),
    action_power_off: require('./assets/action/poweroff.svg'),
    action_print: require('./assets/action/print.svg'),
    action_impersonate: require('./assets/action/impersonate.svg'),
    action_light_on: require('./assets/action/light_on.svg'),
    action_light_off: require('./assets/action/light_off.svg'),
    action_close_octagon_outline: require('./assets/action/close-octagon-outline.svg'),
    action_minus_circle_outline: require('./assets/action/minus-circle-outline.svg'),
    action_plus_circle_outline: require('./assets/action/plus-circle-outline.svg'),
    action_vending_machine: require('./assets/action/vending-machine.svg'),
    action_video: require('./assets/action/video.svg'),
    action_video_off_outline: require('./assets/action/video-off-outline.svg'),
    action_touch_app: require('./assets/action/touch_app.svg'),

    message_alert: require('./assets/message/alert.svg'),
    message_error: require('./assets/message/error.svg'),
    message_info: require('./assets/message/info.svg'),
    message_success: require('./assets/message/success.svg'),
    message_warning: require('./assets/message/warning.svg'),
    message_comment: require('./assets/message/comment.svg'),

    nav_accessibility: require('./assets/nav/accessibility.svg'),
    nav_account: require('./assets/nav/account.svg'),
    nav_all_notifications: require('./assets/nav/all_notifications.svg'),
    nav_analytics: require('./assets/nav/analytics.svg'),
    nav_apartment: require('./assets/nav/apartment.svg'),
    nav_appointment_type: require('./assets/nav/appointment-type.svg'),
    nav_appointment_type_config: require('./assets/nav/appointments-type-config.svg'),
    nav_appointments: require('./assets/nav/appointments.svg'),
    nav_audit_log: require('./assets/nav/audit-log.svg'),
    nav_branch: require('./assets/nav/branch.svg'),
    nav_branches: require('./assets/nav/branches.svg'),
    nav_book: require('./assets/nav/book.svg'),
    nav_bookmark: require('./assets/nav/bookmark.svg'),
    nav_bubble: require('./assets/nav/bubble.svg'),
    nav_bullhorn: require('./assets/nav/bullhorn.svg'),
    nav_cart: require('./assets/nav/shopping-cart.svg'),
    nav_cart_blocked: require('./assets/nav/shopping-cart-blocked.svg'),
    nav_chart: require('./assets/nav/chart-bars.svg'),
    nav_clock: require('./assets/nav/clock.svg'),
    nav_cloud: require('./assets/nav/cloud.svg'),
    nav_cms: require('./assets/nav/cms.svg'),
    nav_cms_tags: require('./assets/nav/cms-tags.svg'),
    nav_config: require('./assets/nav/config.svg'),
    nav_goodie_bag: require('./assets/nav/goodie-bag.svg'),
    nav_construction: require('./assets/nav/construction.svg'),
    nav_content: require('./assets/nav/content.svg'),
    nav_corner_radius: require('./assets/nav/corner_radius.svg'),
    nav_cpu: require('./assets/nav/cpu.svg'),
    nav_cronjobs: require('./assets/nav/cronjobs.svg'),
    nav_custom_pages: require('./assets/nav/custom_pages.svg'),
    nav_custom_product: require('./assets/nav/custom-product.svg'),
    nav_customers: require('./assets/nav/customers.svg'),
    nav_customer: require('./assets/nav/customer.svg'),
    nav_curl: require('./assets/nav/curl.svg'),
    nav_dashboard: require('./assets/nav/dashboard.svg'),
    nav_database: require('./assets/nav/database.svg'),
    nav_delivery: require('./assets/nav/delivery.svg'),
    nav_diamond: require('./assets/nav/diamond.svg'),
    nav_dot: require('./assets/nav/dot.svg'),
    nav_download: require('./assets/nav/download.svg'),
    nav_dropdown: require('./assets/nav/dropdown.svg'),
    nav_earth: require('./assets/nav/earth.svg'),
    nav_envelope: require('./assets/nav/envelope.svg'),
    nav_export: require('./assets/nav/export.svg'),
    nav_exports: require('./assets/nav/exports.svg'),
    nav_faq_categories: require('./assets/nav/faq_categories.svg'),
    nav_faq_items: require('./assets/nav/faq_items.svg'),
    nav_feedback: require('./assets/nav/feedback.svg'),
    nav_file: require('./assets/nav/file-empty.svg'),
    nav_flag: require('./assets/nav/flag.svg'),
    nav_gift: require('./assets/nav/gift.svg'),
    nav_graduation_hat: require('./assets/nav/graduation-hat.svg'),
    nav_heart_pulse: require('./assets/nav/heart-pulse.svg'),
    nav_help: require('./assets/nav/help.svg'),
    nav_home: require('./assets/nav/home.svg'),
    nav_hotairballoon: require('./assets/nav/hotairballoon.svg'),
    nav_hourglass: require('./assets/nav/hourglass.svg'),
    nav_imports: require('./assets/nav/imports.svg'),
    nav_inbox: require('./assets/nav/inbox.svg'),
    nav_integrations: require('./assets/nav/integrations.svg'),
    nav_key: require('./assets/nav/key.svg'),
    nav_language: require('./assets/nav/language.svg'),
    nav_layers: require('./assets/nav/layers.svg'),
    nav_layout: require('./assets/nav/layout.svg'),
    nav_leaf: require('./assets/nav/leaf.svg'),
    nav_link: require('./assets/nav/link.svg'),
    nav_license: require('./assets/nav/license.svg'),
    nav_localisation: require('./assets/nav/localisation.svg'),
    nav_location: require('./assets/nav/location.svg'),
    nav_lochting_news: require('./assets/nav/lochting-news.svg'),
    nav_magic_wand: require('./assets/nav/magic-wand.svg'),
    nav_marketing: require('./assets/nav/marketing.svg'),
    nav_matic: require('./assets/nav/matic.svg'),
    nav_media: require('./assets/nav/media.svg'),
    nav_newspaper: require('./assets/nav/newspaper.svg'),
    nav_nighthatch: require('./assets/nav/nighthatch.svg'),
    nav_notebook: require('./assets/nav/notebook.svg'),
    nav_notifications: require('./assets/nav/notifications.svg'),
    nav_notifications_platform: require('./assets/nav/notifications-platform.svg'),
    nav_orders: require('./assets/nav/orders.svg'),
    nav_paperclip: require('./assets/nav/paperclip.svg'),
    nav_payment: require('./assets/nav/payment.svg'),
    nav_pencil: require('./assets/nav/pencil.svg'),
    nav_pharmacy: require('./assets/nav/pharmacy.svg'),
    nav_pie_chart: require('./assets/nav/pie-chart.svg'),
    nav_picture: require('./assets/nav/picture.svg'),
    nav_pictures: require('./assets/nav/pictures.svg'),
    nav_plausible_analytics: require('./assets/nav/plausible-analytics.svg'),
    nav_prescription: require('./assets/nav/prescription.svg'),
    nav_presentation: require('./assets/nav/presentation.svg'),
    nav_presentations: require('./assets/nav/presentations.svg'),
    nav_price: require('./assets/action/price.svg'),
    nav_prize: require('./assets/nav/prize.svg'),
    nav_product_feedback: require('./assets/nav/product_feedback.svg'),
    nav_product_offer: require('./assets/nav/product_offer.svg'),
    nav_product_walls: require('./assets/nav/product_walls.svg'),
    nav_products: require('./assets/nav/products.svg'),
    nav_promotions: require('./assets/nav/promotions.svg'),
    nav_promo: require('./assets/nav/promo.svg'),
    nav_public_products: require('./assets/nav/public_products.svg'),
    nav_pushpin: require('./assets/nav/pushpin.svg'),
    nav_puzzle: require('./assets/nav/puzzle.svg'),
    nav_resellers: require('./assets/nav/resellers.svg'),
    nav_reservation: require('./assets/nav/reservation.svg'),
    nav_releases: require('./assets/nav/releases.svg'),
    nav_rocket: require('./assets/nav/rocket.svg'),
    nav_scale_down: require('./assets/nav/scale-down-alt.svg'),
    nav_schedule: require('./assets/nav/schedule.svg'),
    nav_screen_devices: require('./assets/nav/screen_devices.svg'),
    nav_search_action_exports: require('./assets/nav/search_action_exports.svg'),
    nav_search_synonyms: require('./assets/nav/search_synonyms.svg'),
    nav_select: require('./assets/nav/select.svg'),
    nav_settings: require('./assets/nav/settings.svg'),
    nav_sharing_groups: require('./assets/nav/sharing_groups.svg'),
    nav_shipping_methods: require('./assets/nav/shipping_methods.svg'),
    nav_shirt: require('./assets/nav/shirt.svg'),
    nav_shop: require('./assets/nav/shop.svg'),
    nav_slideshows: require('./assets/nav/slideshows.svg'),
    nav_sources: require('./assets/nav/sources.svg'),
    nav_star_empty: require('./assets/nav/star-empty.svg'),
    nav_star_half: require('./assets/nav/star-half.svg'),
    nav_stock_replenishment: require('./assets/nav/stock-replenishment.svg'),
    nav_store: require('./assets/nav/store.svg'),
    nav_store_description: require('./assets/nav/store_description.svg'),
    nav_store_layouts: require('./assets/nav/store_layouts.svg'),
    nav_tag: require('./assets/nav/tag.svg'),
    nav_text_format: require('./assets/nav/text-format.svg'),
    nav_tree_view: require('./assets/nav/tree-view.svg'),
    nav_tv: require('./assets/nav/tv.svg'),
    nav_upload: require('./assets/nav/upload.svg'),
    nav_user: require('./assets/nav/user.svg'),
    nav_user_woman: require('./assets/nav/user-woman.svg'),
    nav_users: require('./assets/nav/users.svg'),
    nav_vacation: require('./assets/nav/vacation.svg'),
    nav_vending: require('./assets/nav/vending.svg'),
    nav_vending_devices: require('./assets/nav/vending_devices.svg'),
    nav_wand: require('./assets/nav/magic-wand.svg'),
    nav_webshop: require('./assets/nav/webshop.svg'),
    nav_webshop_customers: require('./assets/nav/customers.svg'),
    nav_webshop_pages: require('./assets/nav/webshop-pages.svg'),
    nav_webshop_selection: require('./assets/nav/webshop_selection.svg'),
    nav_card_account_details_outline: require('./assets/nav/card-account-details-outline.svg'),

    text_align_bottom: require('./assets/text/align_bottom.svg'),
    text_align_center: require('./assets/text/align_center.svg'),
    text_align_full: require('./assets/text/align_full.svg'),
    text_align_left: require('./assets/text/align_left.svg'),
    text_align_right: require('./assets/text/align_right.svg'),
    text_align_top: require('./assets/text/align_top.svg'),
    text_bold: require('./assets/text/bold.svg'),
    text_insert_link: require('./assets/text/insert_link.svg'),
    text_italic: require('./assets/text/italic.svg'),
    text_list_numbered: require('./assets/text/list_numbered.svg'),
    text_list_bullet: require('./assets/text/list_bullet.svg'),
    text_strikethrough: require('./assets/text/strikethrough.svg'),
    text_underlined: require('./assets/text/underlined.svg'),
    text_vertical_align_center: require('./assets/text/vertical_align_center.svg'),

    status_canceled: require('./assets/status/canceled.svg'),
    status_failed: require('./assets/status/failed.svg'),
    status_incomplete: require('./assets/status/incomplete.svg'),
    status_pending: require('./assets/status/pending.svg'),
    status_success: require('./assets/status/success.svg'),
    status_unpaid: require('./assets/status/unpaid.svg'),

    collect_error_locker: require('./assets/collect/error_locker.svg'),
    collect_heating: require('./assets/collect/heating.svg'),
    collect_humidity: require('./assets/collect/humidity.svg'),
    collect_locked: require('./assets/collect/locked.svg'),
    collect_payment_terminal: require('./assets/collect/payment_terminal.svg'),
    collect_screen: require('./assets/collect/screen.svg'),
    collect_temperature: require('./assets/collect/temperature.svg'),
    collect_unlocked: require('./assets/collect/unlocked.svg'),
    collect_ventilation: require('./assets/collect/ventilation.svg'),
    collect_products: require('./assets/collect/products.svg'),
    collect_cooling: require('./assets/collect/cooling.svg'),
    collect_reservation: require('./assets/collect/reservation.svg'),
    collect_barcode: require('./assets/collect/barcode.svg'),
    collect_pick_locker: require('./assets/collect/pick_locker.svg'),
    collect_item_to_stock: require('./assets/collect/item_to_stock.svg'),
    collect_pick_up: require('./assets/collect/pick_up.svg'),

    animation_three_dots_progress_1: require('./assets/animation/three-dots-progress/dots-1.svg'),
    animation_three_dots_progress_2: require('./assets/animation/three-dots-progress/dots-2.svg'),
    animation_three_dots_progress_3: require('./assets/animation/three-dots-progress/dots-3.svg'),
    animation_three_dots_progress_4: require('./assets/animation/three-dots-progress/dots-4.svg'),
    animation_three_dots_progress_5: require('./assets/animation/three-dots-progress/dots-5.svg'),

    currency_eur: require('./assets/currency/eur.svg'),
};

export type IconSize = 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs';

export type IconType = keyof typeof ASSETS;

export const IconTypes: IconType[] = Object.keys(ASSETS) as IconType[];

interface CoreProps {
    iconSize?: IconSize;
}

interface ISVGProps {
    color?: string;
    type: IconType;
}

interface IImgProps {
    src: string;
}

type IProps = CoreProps & (ISVGProps | IImgProps);

export default class Icon extends React.PureComponent<BaseProps & IProps & Omit<React.HTMLProps<HTMLSpanElement>, 'type'>> {
    render() {
        if (this.isSVGIcon(this.props)) {
            const { color, iconSize, type, className, children, ...rest } = this.props;

            return (
                <i
                    {...rest}
                    className={classNames(styles.Icon, styles[iconSize || 'm'], colorTransform(color, 'Text'), className)}
                    dangerouslySetInnerHTML={{ __html: ASSETS[type] }}
                />
            );
        } else {
            const { src, iconSize, style, className, children, ...rest } = this.props;
            return (
                <img
                    {...(rest as any)}
                    className={classNames(styles.Icon, styles[iconSize || 'l'], className)}
                    src={src}
                    alt=""
                />
            );
        }
    }

    isSVGIcon(props: IProps): props is ISVGProps {
        return (props as ISVGProps).hasOwnProperty('type');
    }
}
