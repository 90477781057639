import {
    Pagination,
    Sorting,
    Ownership,
    DeviceScreenResolution,
    DevicesPage,
    DeviceDetails,
    CreateDevicePayload,
    UpdateDevicePayload,
    DeviceLivePreviewSignalPayload,
    UpdateDeviceHiddenSlidesPayload,
    UpdateDeviceConfigurationPayload,
    UpdateDeviceImageWithSizePayload,
    UpdateDeviceHomeScreenProductsPayload,
    UpdateDeviceLabelPayload,
    UpdateVendingMachinePharmacistOnDutySchedulePayload,
    UpdateDeviceInPaymentTestingModePayload,
    UpdateDeviceNotesPayload,
    UpdateDeviceOpeningHoursScheduledTimelineIdPayload,
    UpdateDeviceStandbyModePayload,
    UpdateDeviceMtCollectManagementCodesPayload,
    UpdateDevicePrintLabelConfigurationPayload,
    RequestRobotProductDeliveryPayload,
    CreateRobotEmergencyStopNotificationPayload,
    RequestPickupProductsDeliveryPayload,
    UpdateDeviceEnabledComponentsPayload,
    UpdateDeviceTouchTheScreenCallToActionPositionPayload,
} from '@/Domain';
import { JsonWebToken } from '@/Infrastructure/JWT';

import Endpoint from './Endpoint';

export default class DeviceEndpoint extends Endpoint {
    public async GetDevices(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            screenResolution?: DeviceScreenResolution;
            type?: string;
            groupName?: string;
            isActive?: boolean;
            standbyMode?: string;
        },
    ): Promise<DevicesPage> {
        let url = '/device/overview';
        if (ownership.type === 'branch') {
            url = '/device/overview/branch';
        } else if (ownership.type === 'company') {
            url = `/company/${ownership.ownerId}/devices`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: filters
                    ? {
                          ...filters,
                          group: filters.groupName,
                      }
                    : {},
            },
        });

        this.guardResponseSchema('#/definitions/DevicesPage', response);

        return response;
    }

    public async GetDeviceDetails(deviceId: string): Promise<DeviceDetails> {
        const response = await this.client.get({
            url: '/device/' + deviceId + '/details',
        });

        this.guardResponseSchema('#/definitions/DeviceDetails', response);

        return response;
    }

    public async CreateDevice(payload: CreateDevicePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\CreateDevice\\CreateDevice',
                payload,
            },
        });
    }

    public async UpdateDevice(payload: UpdateDevicePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDevice\\UpdateDevice',
                payload,
            },
        });
    }

    public async RemoveDevice(deviceId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\RemoveDevice\\RemoveDevice',
                payload: {
                    deviceId,
                },
            },
        });
    }

    public async UpdateDeviceHiddenSlides(payload: UpdateDeviceHiddenSlidesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceHiddenSlides',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceHiddenSlides\\UpdateDeviceHiddenSlides',
                payload,
            },
        });
    }

    public async UpdateDeviceConfiguration(payload: UpdateDeviceConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceConfiguration\\UpdateDeviceConfiguration',
                payload,
            },
        });
    }

    public async GetSignalingToken(deviceId: string): Promise<JsonWebToken> {
        const response = await this.client.get({
            url: '/device/get-signaling-token/' + deviceId,
        });

        return new JsonWebToken(response.token, '');
    }

    public async PushLivePreviewToDevice(deviceId: string, content: DeviceLivePreviewSignalPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/PushLivePreviewToDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\PushLivePreviewToDevice\\PushLivePreviewToDevice',
                payload: {
                    deviceId,
                    content,
                },
            },
        });
    }

    public async RefreshDevice(deviceId: string, delayInSeconds: number): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RefreshDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\RefreshDevice\\RefreshDevice',
                payload: {
                    deviceId,
                    delayInSeconds,
                },
            },
        });
    }

    public async ReloadDeviceSoftware(deviceId: string, delayInSeconds: number): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ReloadDeviceSoftware',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\ReloadDeviceSoftware\\ReloadDeviceSoftware',
                payload: {
                    deviceId,
                    delayInSeconds,
                },
            },
        });
    }

    public async RebootDevice(deviceId: string, delayInSeconds: number): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RebootDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\RebootDevice\\RebootDevice',
                payload: {
                    deviceId,
                    delayInSeconds,
                },
            },
        });
    }

    public async ScreenshotDevice(deviceId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ScreenshotDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\ScreenshotDevice\\ScreenshotDevice',
                payload: {
                    deviceId,
                },
            },
        });
    }
    public async RequestRobotProductDelivery(payload: RequestRobotProductDeliveryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RequestRobotProductDelivery',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\RequestRobotProductDelivery\\RequestRobotProductDelivery',
                payload,
            },
        });
    }
    public async RequestPickupProductsDelivery(payload: RequestPickupProductsDeliveryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RequestPickupProductsDelivery',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\RequestPickupProductsDelivery\\RequestPickupProductsDelivery',
                payload,
            },
        });
    }
    public async OpenMtCollectLocker(deviceId: string, moduleId: string, lockerId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/OpenMtCollectLocker',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\MtCollect\\OpenMtCollectLocker\\OpenMtCollectLocker',
                payload: {
                    deviceId,
                    moduleId,
                    lockerId,
                },
            },
        });
    }

    public GetDevicesExportURL(exportType: 'csv' | 'xlsx', deviceType?: DeviceDetails['type']): string {
        return this.client.buildApiURL(`/device/export/${exportType}`, true, {
            filters: { deviceType },
        });
    }

    public async UpdateDeviceLogo(payload: UpdateDeviceImageWithSizePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceLogo',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceLogo\\UpdateDeviceLogo',
                payload,
            },
        });
    }

    public async UpdateDeviceLabel(payload: UpdateDeviceLabelPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceLabel',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceLabel\\UpdateDeviceLabel',
                payload,
            },
        });
    }

    public async UpdateDeviceNotes(payload: UpdateDeviceNotesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceNotes',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceNotes\\UpdateDeviceNotes',
                payload,
            },
        });
    }

    public async UpdateDeviceInPaymentTestingMode(payload: UpdateDeviceInPaymentTestingModePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceInPaymentTestingMode',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceInPaymentTestingMode\\UpdateDeviceInPaymentTestingMode',
                payload,
            },
        });
    }

    public async UpdateDeviceHomeScreenProducts(payload: UpdateDeviceHomeScreenProductsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceHomeScreenProducts',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceHomeScreenProducts\\UpdateDeviceHomeScreenProducts',
                payload,
            },
        });
    }

    public async UpdateVendingMachinePharmacistOnDutySchedule(payload: UpdateVendingMachinePharmacistOnDutySchedulePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateVendingMachinePharmacistOnDutySchedule',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Device\\UpdateVendingMachinePharmacistOnDutySchedule\\UpdateVendingMachinePharmacistOnDutySchedule',
                payload,
            },
        });
    }

    public async UpdateDeviceOpeningHoursScheduledTimelineId(payload: UpdateDeviceOpeningHoursScheduledTimelineIdPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceOpeningHoursScheduledTimelineId',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Device\\UpdateDeviceOpeningHoursScheduledTimelineId\\UpdateDeviceOpeningHoursScheduledTimelineId',
                payload,
            },
        });
    }

    public async UpdateDeviceStandbyMode(payload: UpdateDeviceStandbyModePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceStandbyMode',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceStandbyMode\\UpdateDeviceStandbyMode',
                payload,
            },
        });
    }

    public async UpdateDeviceMtCollectManagementCodes(payload: UpdateDeviceMtCollectManagementCodesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceMtCollectManagementCodes',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceMtCollectManagementCodes\\UpdateDeviceMtCollectManagementCodes',
                payload,
            },
        });
    }

    public async UpdateDevicePrintLabelConfiguration(payload: UpdateDevicePrintLabelConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDevicePrintLabelConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDevicePrintLabelConfiguration\\UpdateDevicePrintLabelConfiguration',
                payload,
            },
        });
    }
    public async UpdateDeviceEnabledComponents(payload: UpdateDeviceEnabledComponentsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceEnabledComponents',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceEnabledComponents\\UpdateDeviceEnabledComponents',
                payload,
            },
        });
    }

    public async CreateRobotEmergencyStopNotification(payload: CreateRobotEmergencyStopNotificationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateRobotEmergencyStopNotificationPayload',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Notification\\CreateRobotEmergencyStopNotification\\CreateRobotEmergencyStopNotification',
                payload,
            },
        });
    }

    public async UpdateDeviceTouchTheScreenCallToActionPosition(
        payload: UpdateDeviceTouchTheScreenCallToActionPositionPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceTouchTheScreenCallToActionPosition',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Device\\UpdateDeviceTouchTheScreenCallToActionPosition\\UpdateDeviceTouchTheScreenCallToActionPosition',
                payload,
            },
        });
    }
}
