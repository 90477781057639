import { Branch } from './Branch';
import { Company } from './Company';
import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
import { User } from './User';
import { Webshop } from './Webshop';

export interface WebshopPromotionCoupon {
    couponCode: string;
    usages: number;
}

export type PromotionType = 'productSelection' | 'cart';

export const PROMOTION_RULE_CONDITIONS = [
    'in',
    'not_in',
    'is',
    'is_not',
    'equal',
    'not_equal',
    'lower_than_or_equal',
    'greater_than_or_equal',
    'lower_than',
    'greater_than',
] as const;

export type PromotionRuleCondition = (typeof PROMOTION_RULE_CONDITIONS)[number];

export interface PromotionRuleConfigCategory {
    categoryIds: string[];
}

export interface PromotionRuleConfigBrand {
    brandIds: string[];
}

export interface PromotionRuleConfigProduct {
    productIds: string[];
}

export interface PromotionRuleConfigCart {
    quantity?: number;
    value?: number;
}

export const PROMOTION_RULE_TYPES_WITH_OPTIONS = ['category', 'brand', 'product'] as const;

export const PROMOTION_RULE_TYPES = [
    'category',
    'brand',
    'product',
    'in_local_stock',
    'cart_has_product',
    'cart_has_product_with_brand',
    'cart_has_product_with_category',
    'cart_total_items_qty',
    'cart_subtotal_incl_vat',
    'cart_subtotal_excl_vat',
] as const;

export type PromotionRuleType = (typeof PROMOTION_RULE_TYPES)[number];

export type PromotionRule =
    | {
          type: 'category';
          condition: 'in' | 'not_in';
          config: PromotionRuleConfigCategory;
      }
    | {
          type: 'brand';
          condition: 'in' | 'not_in';
          config: PromotionRuleConfigBrand;
      }
    | {
          type: 'product';
          condition: 'in' | 'not_in';
          config: PromotionRuleConfigProduct;
      }
    | {
          type: 'in_local_stock';
          condition: 'is' | 'is_not';
          config: null;
      }
    | {
          type: 'cart_has_product';
          condition: 'in';
          config: PromotionRuleConfigProduct & { quantity: string };
      }
    | {
          type: 'cart_has_product_with_brand';
          condition: 'in';
          config: PromotionRuleConfigBrand & { quantity: string };
      }
    | {
          type: 'cart_has_product_with_category';
          condition: 'in';
          config: PromotionRuleConfigCategory & { quantity: string };
      }
    | {
          type: 'cart_total_items_qty';
          condition: PromotionRuleWithNumberConditionType;
          config: { quantity: string };
      }
    | {
          type: 'cart_subtotal_incl_vat' | 'cart_subtotal_excl_vat';
          condition: PromotionRuleWithNumberConditionType;
          config: { value: string };
      };

export const PROMOTION_RULE_TYPES_WITH_TOGGLE = ['in_local_stock'] as const;

export const PROMOTION_RULE_TYPES_WITH_NUMBER_CONDITIONS = [
    'equal',
    'not_equal',
    'lower_than_or_equal',
    'greater_than_or_equal',
    'lower_than',
    'greater_than',
] as const;

export type PromotionRuleWithNumberConditionType = (typeof PROMOTION_RULE_TYPES_WITH_NUMBER_CONDITIONS)[number];

export type PromotionRuleWithNumberType = PromotionRuleWithNumber['type'];
export const PROMOTION_RULE_TYPES_WITH_NUMBER = ['cart_total_items_qty', 'cart_subtotal_incl_vat', 'cart_subtotal_excl_vat'] as const;
export function promotionRuleTypeIsWithNumber(type: PromotionRuleType): type is PromotionRuleWithNumberType {
    return PROMOTION_RULE_TYPES_WITH_NUMBER.indexOf(type as PromotionRuleWithNumberType) > -1;
}

export type PromotionRuleWithNumber = {
    type: (typeof PROMOTION_RULE_TYPES_WITH_NUMBER)[number];
    condition: PromotionRuleWithNumberConditionType;
    values: string[];
};

export const PROMOTION_ACTION_TYPES = ['percentage_off', 'fixed_amount_off', 'buy_x_get_y', 'free_gift'] as const;

export type PromotionActionType = (typeof PROMOTION_ACTION_TYPES)[number];

export interface PromotionActionConfigPercentageOff {
    percentage?: number;
}
export interface PromotionActionConfigFixedAmountOff {
    amount?: number;
}

export interface PromotionActionConfigBuyXGetY {
    buyX?: number;
    getY?: number;
}

export interface PromotionActionConfigFreeGift {
    productId?: string;
    quantity?: number;
}

export type PromotionAction =
    | { type: 'percentage_off'; config: PromotionActionConfigPercentageOff }
    | { type: 'fixed_amount_off'; config: PromotionActionConfigFixedAmountOff }
    | { type: 'buy_x_get_y'; config: PromotionActionConfigBuyXGetY }
    | { type: 'free_gift'; config: PromotionActionConfigFreeGift };

export interface WebshopPromotion {
    promotionId: string;
    webshopId: Webshop['webshopId'];
    ruleName: string;
    localizedLabel: LocalizedValue;
    campaignName?: string | null;
    action: PromotionAction;
    from?: string | null;
    till?: string | null;
    activeStatus: 'active' | 'inactive';
    createdOn: string;
    updatedOn?: string | null;
    authorUserId: User['userId'];
    priority: number;
    discardSubsequentRules?: boolean | null;
    promotionType: PromotionType;
}
export interface WebshopPromotionDetails extends WebshopPromotion {
    campaignName?: string | null;
    coupons: WebshopPromotionCoupon[];
    maxUsagesPerCoupon?: number | null;
    maxUsagesPerCustomer?: number | null;
    removedAt?: string | null;
    rules?: PromotionRule[] | null;
    discardSubsequentRules?: boolean | null;
    companyId: Company['companyId'];
    branchId: Branch['branchId'];
}

export type CreateWebshopPromotionPayload = WebshopPromotionDetails & {
    coupons: Omit<WebshopPromotionCoupon, 'usages'>[];
};
export type UpdateWebshopPromotionPayload = WebshopPromotionDetails & {
    coupons: Omit<WebshopPromotionCoupon, 'usages'>[];
};
export interface WebshopPromotionsPage {
    items: WebshopPromotion[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopPromotion> | null;
    search?: string | null;
}
export interface UpdateWebshopPromotionPrioritiesPayload {
    webshopId: Webshop['webshopId'];
    promotionIds: WebshopPromotion['promotionId'][];
}
