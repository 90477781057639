import { combineReducers } from '@reduxjs/toolkit';

import { apiKeyReducer } from './ApiKey/reducer';
import { appointmentsReducer } from './Appointments/reducer';
import { appointmentTypesReducer } from './AppointmentTypes/reducer';
import { auditLogReducer } from './AuditLog/reducer';
import { authenticationReducer } from './Authentication';
import { availableProductReducer } from './AvailableProduct/reducer';
import { billableServiceReducer } from './BillableService/reducer';
import { branchReducer } from './Branch/reducer';
import { cmsItemReducer } from './CmsItem/reducer';
import { cmsTagReducer } from './CmsTag/reducer';
import { companyReducer } from './Company/reducer';
import { customerReducer } from './Customer/reducer';
import { customPageReducer } from './CustomPage/reducer';
import { customProductReducer } from './CustomProduct/reducer';
import { darkModeReducer } from './DarkMode';
import { dashboardReducer } from './Dashboard';
import { deviceReducer } from './Device/reducer';
import { deviceMonitoringReducer } from './DeviceMonitoring/reducer';
import { deviceOrderReducer } from './DeviceOrder/reducer';
import { deviceOrderExportReducer } from './DeviceOrderExport/reducer';
import { externalSourceOrderReducer } from './ExternalSourceOrder/reducer';
import { fraankReducer } from './Fraank/reducer';
import { i18nReducer } from './I18n/state';
import { integrationReducer } from './Integration/reducer';
import { invoiceReducer } from './Invoice/reducer';
import { localisationReducer } from './Localisation/reducer';
import { mediaReducer } from './Media/reducer';
import { nightHatchReducer } from './NightHatch/reducer';
import { notificationsReducer } from './Notifications';
import { orderReviewReducer } from './OrderReview/reducer';
import { pickupReducer } from './Pickup/reducer';
import { overviewReducer as platformNotificationAdminOverviewReducer } from './PlatformNotification/adminOverviewState';
import { overviewReducer as platformNotificationOverviewReducer } from './PlatformNotification/overviewState';
import { platformNotificationReducer } from './PlatformNotification/state';
import { plausibleReducer } from './Plausible/reducer';
import { productExportReducer } from './ProductExport/reducer';
import { productFeedbackReducer } from './ProductFeedback/reducer';
import { productImportReducer } from './ProductImport/reducer';
import { productSelectionReducer } from './ProductSelection/reducer';
import { productSourceReducer } from './ProductSource/reducer';
import { productWallReducer } from './ProductWall/reducer';
import { qualityLabelReducer } from './QualityLabel/reducer';
import { resellerReducer } from './Reseller/reducer';
import { scheduleReducer } from './Schedule/reducer';
import { searchActionsExportReducer } from './SearchActionsExport/reducer';
import { searchSynonymReducer } from './SearchSynonym/reducer';
import { sharingGroupReducer } from './SharingGroup/reducer';
import { slideshowReducer } from './Slideshow/reducer';
import { stockReplenishReducer } from './StockReplenish/reducer';
import { stockReplenishExportReducer } from './StockReplenishExport/reducer';
import { tagsReducer } from './Tags/reducer';
import { translationReducer } from './Translation/reducer';
import { tvScreenReducer } from './TVScreen/reducer';
import { unassignedWebshopReducer } from './UnassignedWebshop/reducer';
import { userReducer } from './User/reducer';
import { webhookReducer } from './Webhook/reducer';
import { webshopReducer } from './Webshop/reducer';
import { webshopAnnouncementReducer } from './WebshopAnnouncement/reducer';
import { webshopCartRestrictionReducer } from './WebshopCartRestriction/reducer';
import { webshopCustomerReducer } from './WebshopCustomer/reducer';
import { webshopDeliveryTimeReducer } from './WebshopDeliveryTime/reducer';
import { webshopFaqCategoryReducer } from './WebshopFaqCategory/reducer';
import { webshopFaqCategoryLibraryReducer } from './WebshopFaqCategoryLibrary/reducer';
import { webshopFaqItemReducer } from './WebshopFaqItem/reducer';
import { webshopFaqItemLibraryReducer } from './WebshopFaqItemLibrary/reducer';
import { webshopLayoutReducer } from './WebshopLayout/reducer';
import { webshopLibraryNewsArticleReducer } from './WebshopLibraryNewsArticle/reducer';
import { webshopNewsArticleReducer } from './WebshopNewsArticle/reducer';
import { webshopNewsletterSubscriptionReducer } from './WebshopNewsletterSubscription/reducer';
import { webshopOrderReducer } from './WebshopOrder/reducer';
import { webshopOrderExportReducer } from './WebshopOrderExport/reducer';
import { webshopOrderShipmentReducer } from './WebshopOrderShipment/reducer';
import { webshopPageReducer } from './WebshopPage/reducer';
import { webshopPageLibraryReducer } from './WebshopPageLibrary/reducer';
import { webshopPrescriptionReducer } from './WebshopPrescription/reducer';
import { webshopPromotionReducer } from './WebshopPromotion/reducer';
import { webshopShippingMethodReducer } from './WebshopShippingMethod/reducer';
import { webshopStockSubscriptionReducer } from './WebshopStockSubscription/reducer';
import { webshopTagReducer } from './WebshopTag/reducer';

export const rootReducer = combineReducers({
    i18n: i18nReducer,
    authentication: authenticationReducer,
    notifications: notificationsReducer,
    localisation: localisationReducer,
    translation: translationReducer,
    user: userReducer,
    company: companyReducer,
    branch: branchReducer,
    media: mediaReducer,
    productSource: productSourceReducer,
    availableProduct: availableProductReducer,
    productSelection: productSelectionReducer,
    customProduct: customProductReducer,
    productWall: productWallReducer,
    customPage: customPageReducer,
    slideshow: slideshowReducer,
    schedule: scheduleReducer,
    webshop: webshopReducer,
    webshopPage: webshopPageReducer,
    webshopPageLibrary: webshopPageLibraryReducer,
    webshopPrescription: webshopPrescriptionReducer,
    webshopOrder: webshopOrderReducer,
    webshopNewsArticle: webshopNewsArticleReducer,
    webshopTag: webshopTagReducer,
    webshopDeliveryTime: webshopDeliveryTimeReducer,
    webshopLayout: webshopLayoutReducer,
    WebshopNewsletterSubscription: webshopNewsletterSubscriptionReducer,
    webshopPromotion: webshopPromotionReducer,
    webshopFaqCategory: webshopFaqCategoryReducer,
    webshopFaqItem: webshopFaqItemReducer,
    webshopFaqCategoryLibrary: webshopFaqCategoryLibraryReducer,
    webshopFaqItemLibrary: webshopFaqItemLibraryReducer,
    webshopLibraryNewsArticle: webshopLibraryNewsArticleReducer,
    device: deviceReducer,
    deviceOrder: deviceOrderReducer,
    tvScreen: tvScreenReducer,
    sharingGroup: sharingGroupReducer,
    unassignedWebshop: unassignedWebshopReducer,
    deviceMonitoring: deviceMonitoringReducer,
    integration: integrationReducer,
    fraank: fraankReducer,
    plausible: plausibleReducer,
    platformNotification: platformNotificationReducer,
    platformNotificationOverview: platformNotificationOverviewReducer,
    platformNotificationAdminOverview: platformNotificationAdminOverviewReducer,
    productFeedback: productFeedbackReducer,
    pickup: pickupReducer,
    reseller: resellerReducer,
    webshopShippingMethod: webshopShippingMethodReducer,
    webshopCustomer: webshopCustomerReducer,
    webshopOrderShipment: webshopOrderShipmentReducer,
    invoice: invoiceReducer,
    billableService: billableServiceReducer,
    qualityLabel: qualityLabelReducer,
    nightHatch: nightHatchReducer,
    productExport: productExportReducer,
    searchSynonym: searchSynonymReducer,
    webshopOrderExport: webshopOrderExportReducer,
    deviceOrderExport: deviceOrderExportReducer,
    productImport: productImportReducer,
    webshopCartRestriction: webshopCartRestrictionReducer,
    dashboardReducer: dashboardReducer,
    searchActionsExport: searchActionsExportReducer,
    orderReview: orderReviewReducer,
    apiKey: apiKeyReducer,
    webhook: webhookReducer,
    webshopAnnouncement: webshopAnnouncementReducer,
    darkMode: darkModeReducer,
    cmsItem: cmsItemReducer,
    cmsTag: cmsTagReducer,
    externalSourceOrder: externalSourceOrderReducer,
    webshopStockSubscription: webshopStockSubscriptionReducer,
    appointmentTypesReducer: appointmentTypesReducer,
    appointmentsReducer: appointmentsReducer,
    stockReplenish: stockReplenishReducer,
    stockReplenishExportReducer: stockReplenishExportReducer,
    auditLog: auditLogReducer,
    customer: customerReducer,
    tags: tagsReducer,
});
